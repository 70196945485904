<template>
  <div class="cookie-policy">
    <h2>What is a cookie?</h2>
    <p>Most websites you visit will use cookies in order to improve your user experience by enabling that website to ‘remember’ you, either for the duration of your visit (using a ‘session cookie’) or for repeat visits (using a ‘persistent cookie’).</p>
    <p>Cookies are simple ’text files’ which you can read using the Notebook program on your own PC. Typically, they contain two pieces of information: a site name and unique user ID.</p>
    <p>When you visit a site that uses cookies for the first time, a cookie is downloaded onto your PC. The next time you visit that (or partner) site, your PC checks to see if it has a cookie that is relevant (that is, one containing the site name).</p>
    <p>The site then ’knows’ that you have been there before, and in some cases, tailors content to take account of that fact. For instance, it can be helpful to vary content according to whether this is your first ever visit to a site – or your 71st. We use cookies to identify members interacting with our website.</p>
    <p>Cookies used on our website:</p>
    <div id="cookie-table">
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Host</th>
            <th>Type</th>
            <th>System</th>
            <th>Session</th>
            <th>Duration</th>
            <th>Duration Justification</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>vuex</td>
            <td>.getactivehub.co.uk</td>
            <td>Local Storage</td>
            <td>First party owned</td>
            <td>Persistent</td>
            <td>Over 13 months</td>
            <td>The local storage key/value is set for this duration to persist the users session on the HSBC GAH site.</td>
          </tr>
          <tr>
            <td>_gat_UA-182872000-2</td>
            <td>.getactivehub.co.uk</td>
            <td>HTPP Cookie</td>
            <td>Third party owned -Google Analytics / Tag Manager</td>
            <td>Session</td>
            <td>N/A as Session Only</td>
            <td>The cookies are set for this duration in order to attribute actions taken by users on the HSBC GAH site.</td>
          </tr>
          <tr>
            <td>_gid</td>
            <td>.getactivehub.co.uk</td>
            <td>HTPP Cookie</td>
            <td>Third party owned -Google Analytics / Tag Manager</td>
            <td>Session</td>
            <td>N/A as Session Only</td>
            <td>The cookies are set for this duration in order to attribute actions taken by users on the HSBC GAH site.  </td>
          </tr>
          <tr>
            <td>_ga</td>
            <td>.getactivehub.co.uk</td>
            <td>HTPP Cookie</td>
            <td>Third party owned -Google Analytics / Tag Manager</td>
            <td>Session</td>
            <td>Over 13 months</td>
            <td>The cookies are set for this duration in order to attribute actions taken by users on the HSBC GAH site.  </td>
          </tr>
          <tr>
            <td>XSRF-TOKEN</td>
            <td>http://britishcycling.lassoplatform.com/</td>
            <td>HTPP Cookie</td>
            <td>First party owned</td>
            <td>Session</td>
            <td>Under 3 Months </td>
            <td>The cookies are set for this duration in order to prevent CSRF attacks per session visit on the HSBC GAH admin site.</td>
          </tr>
          <tr>
            <td>laravel_session</td>
            <td>http://britishcycling.lassoplatform.com/</td>
            <td>HTPP Cookie</td>
            <td>First party owned</td>
            <td>Session</td>
            <td>Under 3 Months </td>
            <td>The cookies are set for this duration in order to persist the user session on the HSBC GAH admin site.</td>
          </tr>
          <tr>
            <td>JSESSIONID, TSNGUID, biz_*</td>
            <td>http://britishcycling.lassoplatform.com/</td>
            <td>HTPP Cookie</td>
            <td>Third party owned - New Relic</td>
            <td>Session</td>
            <td>Under 3 Months </td>
            <td> <a href="https://docs.newrelic.com/docs/browser/new-relic-browser/page-load-timing-resources/new-relic-cookies-used-browser">https://docs.newrelic.com/docs/browser/new-relic-browser/page-load-timing-resources/new-relic-cookies-used-browser </a>	</td>
          </tr>
          <tr>
            <td>ajs_anonymous_id</td>
            <td>http://britishcycling.lassoplatform.com/</td>
            <td>HTPP Cookie</td>
            <td>Third party owned - New Relic</td>
            <td>Session</td>
            <td>3-13 Months</td>
            <td>The cookies are set for this duration in order to help us count how many people visit the HSBC GAH admin site by tracking if they’ve visited before.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookiePolicyPage',
}
</script>

<style lang="scss" scoped>
#cookie-table {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  &:before {
    display: none;
  }
  thead {
    text-align: left;
    th {
      padding: 8px 20px;
      font-size: 12px;
      @media screen and (max-width: 768px) {
        div {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
    & th:nth-child(1) {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    & th:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    tr:nth-child(1) th {
      border: 1px solid $border-color;
    }
  }
  tbody {
    tr {
      td {
        padding: 9px 20px;
        font-size: 14px;
        border: 1px solid $border-color;
      }
    }
  }
}
</style>
